<template lang="html">
  <div class="title">
    <div class="">
      <transition
        name="fade"
        enter-active-class="fadeInRight"
        leave-active-class="fadeOutRight"
      >
        <div class="title__location" v-if="locationTitle">
          {{ locationTitle }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
export default {
  name: "Title",
  components: {},
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  mounted() {
    this.locationTitle = this._vaData.locations.find(
      l => l.id === this._vaData.settings.start_location
    ).title;

    bus.$on("location_reached", id => {
      console.log(this._vaData.locations.find(l => l.id === id));

      this.locationTitle = this._vaData.locations.find(l => l.id === id).title;
    });

    bus.$on("location_left", () => {
      this.locationTitle = false;
    });
  },
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: false
    };
  }
};
</script>

<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";

.title {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  flex-shrink: 0;
}

.title__main {
  margin: 0;
  font-size: 1.4em;
  text-transform: uppercase;
}

.title__location {
  overflow: hidden;
  font-size: 1.2em;
  text-transform: uppercase;
}
</style>
