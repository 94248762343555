<template lang="html">
  <SlideOut
    append-to=".videoapp"
    panel="#panel"
    dock="right"
    :visible.sync="visible"
    size="100%"
    mask-color="rgba(0,0,0,0)"
    :title="article.title"
    custom-class="article-slideout"
    @close="onClose"
  >
    <vue-custom-scrollbar id="slideOut__container">
      <div class="slideout_text">
        <div class="uk-background-muted uk-padding body">
          <h1
            v-if="article.title"
            v-html="article.title"
            class="body__title"
            id="element"
          ></h1>
          <h3
            v-if="article.subtitle"
            v-html="article.subtitle"
            class="body__subtitle"
          ></h3>
          <div v-if="article.body" class="body_text">
            <vue-markdown>
              {{ article.body }}
            </vue-markdown>
          </div>
        </div>
      </div>

      <Article
        class="uk-background-default articles"
        v-for="(slide, index) in article.slides"
        :key="index"
        :slide="slide"
      ></Article>
      <div
        v-if="!isElectron"
        class="uk-background-default uk-padding-small uk-margin-large-bottom ios_margin"
      >
        <div
          class="uk-child-width-expand uk-margin-remove-top uk-margin-remove-left "
          uk-grid
        >
          <div
            v-if="article.product_link"
            class="finder_link__button uk-button"
          >
            <a
              class="finder_link uk-button uk-width-1-1"
              :href="article.product_link"
              target="_blank"
            >
              <span uk-icon="icon: world" class="margin-small-right"></span
              >{{ strings.product_link }}</a
            >
          </div>
        </div>
      </div>
    </vue-custom-scrollbar>
  </SlideOut>
</template>

<script>
import SlideOut from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/lib/slideout.css";
import vueCustomScrollbar from "vue-custom-scrollbar";
import isElectron from "is-electron";
import { bus } from "../../main";
import Article from "./Article.vue";
export default {
  data: function() {
    return {
      visible: false,
      strings: this._vaData.strings,
      article: Object,
      isElectron: isElectron()
    };
  },
  props: {},
  components: {
    SlideOut,
    Article,
    vueCustomScrollbar
  },
  methods: {
    onClose() {
      var myDiv1 = document.getElementById("slideOut__container");
      myDiv1.scrollTop = 0;
      this.article = {};
      console.log("hide_slideout");
      document.getElementById("videothing").classList.remove("slide_slideout");
    }
  },
  mounted() {
    bus.$on("show_slideout", data => {
      if (data) {
        this.article = data;
        this.visible = true;

        console.log("show_slideout");
        document.getElementById("videothing").classList.add("slide_slideout");
        // document.getElementById("videothing").style.right = "20%";
      } else {
        // mach das bestehende slideout auf
      }
    });
    bus.$on("hide_slideout", () => {
      this.article = {};
      this.visible = false;
    });
  }
};
</script>

<style lang="scss" scoped="">
body,
html {
  &.is-mobile {
    .body__title {
      font-size: calc(var(--content_size__title) - 6px);
    }
    .body_text {
      font-size: calc(var(--content_size__body) - 2px);
    }
  }
}
.slideout_text {
}
.body__title {
  margin-bottom: 20px;
  color: var(--content_color__title);
  text-transform: var(--content_title_transform);
  font-size: var(--content_size__title);
}
.body__subtitle {
  color: var(--content_color__body);
  display: block;
  font-size: 1.6em;
  line-height: 1.2;
  margin-top: 0;
}
ul {
  margin-bottom: 10px;
}
.articles {
  padding: 30px;
}
.vue-slideout-header {
  padding: 5px 30px;
}
.body_text {
  color: var(--content_color__body);
  font-size: var(--content_size__body);
  margin: 0;
  margin-bottom: 0 !important;
  p {
    margin: 0;
    margin-bottom: 0 !important;
  }
}
.margin-small-right {
  margin-right: 5px !important;
}
.finder_link__button {
  a {
    color: #fff;
  }
}
</style>
