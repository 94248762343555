<template lang="html">
  <vue-custom-scrollbar class="locationMenu">
    <button
      class="locationMenu__entry"
      :class="'locationMenu__entry--' + entry.type"
      @click="click(entry)"
      v-for="(entry, index) in entries"
      :key="index"
    >
      <img
        v-if="entry.config.icon"
        :src="_vaData.apiBase + entry.config.icon.url"
      />
      {{ entry.title }}
    </button>
  </vue-custom-scrollbar>
</template>

<script>
// alle buttons der aktuellen location in einem prakrischen, wunderschönen und scrollbaren menu
import vueCustomScrollbar from "vue-custom-scrollbar";
import { bus } from "../../main";
export default {
  name: "LocationMenu",
  components: { vueCustomScrollbar },
  data() {
    return {
      entries: []
    };
  },
  mounted() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      // do not modify locationData for reasons -> use clone
      this.entries = [...location.buttons];
    });

    bus.$on("location_left", () => {
      this.entries = [];
    });
  },
  methods: {
    click(entry) {
      if (entry.type === "hotspot") {
        // open content
        bus.$emit("show_content", entry.target_content.id);
      } else if (entry.type === "navigation") {
        // got to Location
        bus.$emit("go_to_location", entry.target_location.id);
      } else {
        console.log("unknown entry.type");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.locationMenu {
  height: 100%;
  // min-height: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}
.locationMenu__entry {
  width: 100%;
  border: 0;
  display: inline-block;
  text-align: left;
  background: rgba(#e6e5e6, 0.25);
  color: #000;
  padding: 15px;
  padding-right: 45px;
  margin-bottom: 1px;
  text-transform: var(--button_size_transform);
  cursor: pointer;
  transition: all 0.2s;
  position: relative;

  &:last-child {
    margin: 0;
  }

  .ps--active-y & {
    padding-right: 53px;
  }
  &:after,
  &:before {
    content: "";
    height: 1.5em;
    width: 1.5em;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    background-size: contain;
    transition: opacity 0.2s;
    background-repeat: no-repeat;
  }

  &.locationMenu__entry--navigation {
    &:after {
      background-image: url(../../assets/arrow_single_right.svg);
      background-color: var(--button_color__navigation);
    }
    &:before {
      background-image: url(../../assets/arrow_single_right.svg);
      background-color: var(--button_color__navigation);
    }
  }
  &.locationMenu__entry--hotspot {
    &:after {
      background-image: url(../../assets/more.svg);
      background-color: var(--button_color__hotspot);
    }
    &:before {
      background-image: url(../../assets/more.svg);
      background-color: var(--button_color__hotspot);
    }
  }

  &:hover {
    background: rgba(#e6e5e6, 0.5);

    &:before {
      opacity: 1;
    }
    &:after {
      opacity: 0;
    }
  }
}
</style>
