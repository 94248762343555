<template lang="html">
  <footer class="footer">
    <div class="external">
      <a
        class="footer__button icon-button button__icon--website"
        v-if="strings.website_url"
        :href="strings.website_url"
        target="_blank"
      ></a>
      <a
        class="footer__button icon-button button__icon--phone"
        v-if="strings.phone"
        :href="strings.phone"
        target="_self"
      ></a>
      <a
        class="footer__button icon-button button__icon--mail"
        v-if="strings.mail_url"
        :href="strings.mail_url"
        target="_blank"
      ></a>
      <!-- <a
        class="footer__button icon-button button__icon--calendar"
        v-if="strings.booking_title"
        @click="showBooking"
        :title="_vaData.strings.booking_title"
      ></a> -->
    </div>
    <div class="navigation">
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          v-if="showBack"
          class="footer__button icon-button icon-button--back"
          @click="goBack"
        ></div>
      </transition>
      <Navigation class="footer__button" />
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          class="footer__button icon-button icon-button--home"
          @click="goHome"
        ></div>
      </transition>
    </div>
  </footer>
</template>

<script>
import { bus } from "../../main";
import Navigation from "../Navigation.vue";
// import Booking from "../Booking.vue";
export default {
  components: {
    Navigation
  },
  data() {
    return {
      showBack: false,
      locations: this._vaData.locations,
      strings: this._vaData.strings,
      isHome: true
    };
  },
  mounted() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.showBack = location.show_back_btn && history.length > 0;
      this.isHome = this._vaData.settings.start_location === id;
    });

    bus.$on("location_left", () => {
      this.showBack = false;
    });
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goHome() {
      bus.$emit("go_to_location", this._vaData.settings.start_location);
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    }
    // showBooking: function() {
    //   this.$modal.show(Booking, {
    //     height: "auto",
    //     width: "300",
    //     adaptive: true,
    //     scrollable: true
    //   });
    // }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 2000;
  background: #e6e5e6;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  color: #fff;
  height: 50px;

  & > div {
    height: 100%;
  }

  .footer__button {
    background-color: var(--footer_color);
    text-transform: var(--font_title_transform);
    border-left: 1px solid rgba(#fff, 0.1);
    border-right: 1px solid rgba(#000, 0.1);

    text-align: center;
    box-sizing: border-box;
    transition: all 0.2s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child() {
      border-right: 0;
    }
    &:first-child() {
      border-left: 0;
    }

    &:hover {
      background-color: var(--footer_color);
      text-decoration: none;
    }

    ::v-deep .trigger {
      padding: 0 20px;
    }
  }

  .external,
  .navigation {
    display: flex;
    flex-direction: row;
  }

  .icon-button {
    display: block;
    width: 50px;
    height: 100%;
    background-color: var(--footer_color);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
  }
}
.icon-button--home {
  background-size: auto 54%;
  background-image: url(../../assets/haus.svg);
}
.icon-button--back {
  background-size: auto 64%;
  background-image: url(../../assets/arrow_left.svg);
}
</style>
