<template>
  <div class="title">
    <div v-if="parentLocation" class="breadcrumb">
      <span>
        <a class="title__location" @click="goHome">{{
          this.settings.breadcrumb_location
        }}</a>
      </span>
      <span
        v-if="parentLocation && !parentLocation.parent_location"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location active-tier">
          {{ locationTitle }}
        </h2>
      </span>
      <span
        v-if="parentLocation && parentLocation.parent_location"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2
          class="title__location active-tier--hover"
          @click="goToLocation(parentLocation.slug)"
        >
          {{ parentLocation.title }}
        </h2>
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location active-tier">
          {{ locationTitle }}
        </h2>
      </span>
    </div>
    <div v-else class="breadcrumb">
      <h2 class="title__location active-tier">
        {{ locationTitle }}
      </h2>
    </div>
  </div>
</template>
<script>
import { bus } from "../main";

export default {
  name: "Title",
  data() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locationTitle: null,
      parentLocation: null,
      parentLocationParent: null,
      parentSlug: null
    };
  },
  props: {},
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    },
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  created() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
      // this.parentLocationParent = location.parent_location.parent_location;
      // console.log(this.parentLocation);
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
      this.parentLocation = null;
      this.parentLocationParent = null;
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  width: 100%;

  align-items: flex-end;
  gap: 15px;
  justify-content: flex-start;
}

.title__main {
  line-height: 1;
  color: #000000;
  margin: 0;
  font-size: 2em;
  font-weight: var(--font_weight);
}

.title-border {
  border-left: 1px solid var(--font_color__title);
  padding-left: 1em;
  float: left;
  @media (max-width: $breakpoint-large) {
    display: none;
  }
}

.title__location {
  font-weight: var(--font_weight);
  color: var(--font_color__title);
  text-transform: var(--font_title_transform);
  margin: 0;
  line-height: 1;
  font-size: var(--title_size);
  margin-right: 10px;
  margin-left: 10px;
}
a {
  &:hover {
    text-decoration: none;
    pointer-events: all;
    color: var(--font_color__highlight);
  }
}
.breadcrumb {
  position: absolute;
  transform: translateY(-50%);
  font-weight: var(--font_weight);
  top: 50%;
  margin: 1px 0;
  color: #fff;
  height: 18px;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.breadcrumb_text {
  display: flex;
  align-items: center;
}
.button__icon {
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.button__icon--breadcrumb {
  background-image: url(../assets/arrow_right.svg);
}
.active-tier {
  color: var(--font_color__highlight);
}
.active-tier--hover {
  &:hover {
    cursor: pointer;
    color: var(--font_color__highlight);
  }
}
</style>
